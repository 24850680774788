import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 16px;
  background: #ffffff;
  border: 1px solid #ccd2e3;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-family: 'Utopia Std', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;
