import React from 'react';
import * as S from './RepurchaseFormV1.styles';

const RepurchaseFormV1 = ({ title }) => {
  return (
    <S.Container>
      {title}
      <img src={'/images/success-circle.svg'} alt={'✔'} />
    </S.Container>
  );
};

export default RepurchaseFormV1;
